import React from 'react';
import { withRouter } from 'react-router';
import { Treemap } from 'd3plus-react'

import data from '../database/db2.json'

class TreeMapView extends React.Component {
  state = {
    methods: {
      data,
      size: d => d.value,
      groupBy: d => `${d.id}: ${d.compliance}%`,
      tooltipConfig: {
        body: d => {
          var table = "<table class='tooltip-table'>";
          table += `<tr><td class='title'><b>Compliance: </b></td><td class='data'><b>${d.compliance}%</b></td></tr>`;
          table += `<tr><td class='title tab'>Coverage: </td><td class='data'>${Math.round(d.coverage * 50) / 100}%</td></tr>`;
          table += `<tr><td class='title tab'>Build: </td><td class='data'>${Math.round(d.build * 25) / 100}%</td></tr>`;
          table += `<tr><td class='title tab'>Deploy: </td><td class='data'>${Math.round(d.deploy * 25) / 100}%</td></tr>`;
          table += `<tr><td class='title'>Linhas de Código: </td><td class='data'>${d.value}</td></tr>`;
          table += '</table>';
          return table;
        },
        footer: d => {
          return "<sub class='tooltip-footer'>Minutrade</sub>";
        },
        title: d => d.id
      },
      on: {
        click: d => {
          // if (d.hasSonar) {
          //   window.open(
          //     `https://sonar-dev.bonuz.com/dashboard?id=${d.id}`,
          //     '_blank'
          //   );
          // }
          document.querySelector('.d3plus-tooltip').style.display = 'none';
          this.props.history.push(`/project/${d.id}`);
        }
      },
      shapeConfig: {
        fill: d => {
          if (d.compliance >= 80) {
            return '#3c6382' 
          }
  
          if (d.compliance >= 50) {
            return '#fad390'
          }
  
          return '#E74C3C';
        }
      },
      legend: false
    }  
  }

  render(){
    const { state } = this;
    return (
      <Treemap config={state.methods} />
    )
  }
}

export default withRouter(TreeMapView);