import React from 'react';
import dataBase from '../database/db2.json'

export default props => {
  const { projectCode } = props;

  console.log(projectCode);

  if (!projectCode) return null;

  let project = dataBase.find(p => p.id === projectCode);

  console.log(project);

  return (
    <div>
      <div className="area-page">
        <div className="area-title">
          <h2>Detalhes do projeto {project.id}</h2>
        </div>

        <div className="area-details">
          <div className="field"><span className="label"> Compliance: </span> {project.compliance}%</div>

          <div className="tab">
            <div className="field"><span className="label"> Cobertura de código (Coverage): </span> {Math.round(project.coverage * 50) / 100}%</div>
          </div>

          <div className="tab">
            <div className="field"><span className="label"> Build: </span> {Math.round(project.build * 25) / 100}%</div>
            <div className="tab">
              <div className="field"><span className="label"> Projeto configurado no CircleCI: </span> {project.hasCI ? Math.round(100 * 25 / 3) / 100 : 0}%</div>
              <div className="field"><span className="label"> Projeto configurado no CircleCI 2.0: </span> {project.hasBuild ? Math.round(100 * 25 / 3) / 100 : 0}%</div>
              <div className="field"><span className="label"> Projeto configurado no Sonar: </span> {project.hasSonar ? Math.round(100 * 25 / 3) / 100 : 0}%</div>
            </div>
          </div>

          <div className="tab">
            <div className="field"><span className="label"> Deploy: </span> {Math.round(project.deploy * 25) / 100}%</div>
            <div className="tab">
              <div className="field"><span className="label"> Deploy em DEV: </span> {project.devDeploy ? Math.round(100 * 25 / 3) / 100 : 0}%</div>
              <div className="field"><span className="label"> Deploy em HMG: </span> {project.hmgDeploy ? Math.round(100 * 25 / 3) / 100 : 0}%</div>
              <div className="field"><span className="label"> Deploy em PRD: </span> {project.prdDeploy ? Math.round(100 * 25 / 3) / 100 : 0}%</div>
            </div>
          </div>

          <div className="field"><span className="label"> Número de linhas de código: </span> {project.value}</div>

          {project.hasSonar
            ? <div className="field"><span className="label"> <a href={`https://sonar-dev.bonuz.com/dashboard?id=${project.id}`} target="_blank" rel="noopener noreferrer">Link do projeto no Sonar</a> </span></div>
            : null}

        </div>
      </div>
    </div>
  );

}